import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable } from "aurelia-framework";

@autoinject
export class MaSignatureAndPad {

    @bindable public id: any;
    @bindable public getList!: () => { transport: any, mapResults: any};
    @bindable public onSignatureCompleted!: () => any;

    @bindable public plainTextSignature: string = "";
    @bindable public email: string = "";
    @bindable public title: string = "";

    @bindable public showPlainTextSignature: boolean = true;
    @bindable public showEmailSection: boolean = true;

    @bindable public plainTextSignatureMaxLength: number = 999;

    @bindable public disabled: boolean = false;
    @bindable public required: boolean = false;
    @bindable public emailRequired: boolean = false;

    @bindable @observable public selectedContact: any;

    public displayRequired: string = "";
    public isSignaturePadOpened: boolean = false;

    constructor(private readonly i18n: I18N) {}

    public bind(): void {
        this.displayRequired = this.required ? `(${this.i18n.tr("Required")})` : "";
    }

    public selectedContactChanged(newValue: any): void {
        if (!newValue) {
            this.plainTextSignature = "";
            this.email = "";
            return;
        }
        this.plainTextSignature = newValue.text;
        this.email = newValue.data.data;

    }

    public openSignaturePad(): void {
        this.isSignaturePadOpened = true;
    }
}
