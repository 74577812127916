import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { ServiceDispatchType } from "enums/service-dispatch-type";
import routerHelper from "helpers/routerHelper";
import additionalFieldsService from "services/additionalFieldsService";

@autoinject
export class AdditionalFieldsService {

    public actions: any = {};
    public serviceType: string = "";
    public dispatchId: number = 0;
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.ServiceCall;
    public saveAdditionalFieldsButton: any = true;

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator) {
    }

    public activate(params: any): void {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
    }

    public bindViewModel(serviceType: string, dispatchId: number, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.serviceType = serviceType;
        this.dispatchId = dispatchId;

        if (serviceType === ServiceDispatchType.Service) {
            this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.i18n.tr("CurrentVisit")})`);
        }
    }

    public bind(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForDispatch.bind(additionalFieldsService, this.serviceType, this.dispatchId),
            setAll: additionalFieldsService.setAllForDispatch.bind(additionalFieldsService, this.serviceType, this.dispatchId)
        };
    }
}
