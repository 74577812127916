import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as notificationHelper} from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { default as templateService } from "services/templateService";
import { default as materialRequisitionService } from "services/materialRequisitionService";
import serviceService from "services/serviceService";
import { default as enumHelper } from "helpers/enumHelper";

@inject(I18N)
export default class MaterialRequisitionItem {

    dispatchId;
    lineNo = '';
    requisitionDate = null;
    requisitionId = '';
    showNotInCatalog = false;
    readonly = false;
    serviceType = "S";

    constructor(i18n) {
        this.i18n = i18n;
    }

    setSpecificFields(item) {
        item.DispatchId = this.dispatchId;
        return item;
    }

    bindComponentActions() {
        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            saveMaterialRequisitionItem: (requisitionId, lineNo, item) => materialRequisitionService.setMaterialRequisitionItemForService(this.dispatchId, requisitionId, lineNo, item),
            getActivities: () => serviceService.getActivitiesByDispatch(this.serviceType, this.dispatchId, enumHelper.workOrderActivityType().MATERIAL)
        };
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        this.dispatchId = params.dispatchId;
        this.requisitionDate = dateHelper.dateFromNavigationParameter(params.requisitionDate);
        this.requisitionId = params.requisitionId;
        this.catalogBaseUrl = apiHelper.getCatalogServiceBaseUrl(params.serviceType) + "dispatch/" + params.dispatchId + "/materialrequisitions";
        this.lineNo = params.lineNo;
        this.serviceType = params.serviceType;

        if (params.q) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }
        this.bindComponentActions();

        //get template from dispatch...
        return templateService.getCurrentDispatchTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialService;
        });
    }
}