import defaultService from "services/defaultService";
import serviceService from "services/serviceService";
import dateHelper from "helpers/dateHelper";
import { autoinject } from "aurelia-framework";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import phoneHelper from "helpers/phoneHelper";
import enumHelper from "helpers/enumHelper";
import { I18N } from "aurelia-i18n";
import val from "core/val";
import _ from "underscore";
import queryStringHelper from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";
import { ServiceCallDispatchOrderService } from "services/service-call-dispatch-order-service";

@autoinject
export class SericeInformation {

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public phoneHelper: typeof phoneHelper = phoneHelper;

    public dispatchId: number = 0;
    public informations: any;
    public notes: any[] = [];
    public serviceType: string = "";
    public statusDisabled: boolean = true;
    public statusList: any[] = [];
    public selectedStatus: any;
    public dispatchStatus: any;
    public readonly: boolean = false;
    public enumPresence: any = enumHelper.servicePresence();
    public detail: any;
    public fromHistory: boolean = false;

    public serviceTypeEnum: any = { serviceCall: "S", workOrder: "W" };

    public get statusPlaceHolder(): string {
        if (this.dispatchStatus.CustomStatus !== "") {
            return this.dispatchStatus.CustomStatus + " = " + this.dispatchStatus.CustomStatusDescription;
        } else {
            return this.statusDisabled ? this.i18n.tr("NoStatusAvailable") : this.i18n.tr("SelectOoo");
        }
    }

    public orderNoMaxLength: number = val.get("service.serviceCall.OrderNo", "maxLength");

    constructor(private readonly i18n: I18N, private readonly serviceCallDispatchOrderService: ServiceCallDispatchOrderService) {
    }

    public async activate(params: any): Promise<void> {
        this.readonly = queryStringHelper.parseReadonly(params.q);

        this.fromHistory = queryStringHelper.parse(params.q, "fromHistory");
        this.fromHistory = !!this.fromHistory;

        this.bindViewModel(params.serviceType, params.dispatchId);
        await this.loadData();
    }

    public attached(): void {
        this.initDropDownList();
    }

    public bindViewModel(serviceType: string, dispatchId: number): void {
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public async loadData(): Promise<void>  {

        const [dfdStatus, dfdDispatchStatus, dfdInfos, dfdNotes, details]: any = await Promise.all([
            serviceService.getCustomStatus(this.serviceType),
            serviceService.getStatus(this.serviceType, this.dispatchId),
            serviceService.getInformation(this.serviceType, this.dispatchId),
            serviceService.getNotes(this.serviceType, this.dispatchId),
            serviceService.getDetail(this.serviceType, this.dispatchId, this.fromHistory)
        ]);

        this.detail = details;
        this.informations = dfdInfos;
        this.notes = dfdNotes;

        if (!this.readonly) {
            this.readonly = this.detail.IsClosed || this.detail.Presence === this.enumPresence.COMPLETED.id;
        }

        const status = _.map(dfdStatus, (tmpData: any) =>  {
            return {
                id: tmpData.Id,
                text: tmpData.Id + " - " + tmpData.Description
            };
        });

        this.statusList = status;
        this.statusDisabled = this.statusList.length === 0 || this.readonly;
        this.dispatchStatus = dfdDispatchStatus;

        if (this.dispatchStatus.CustomStatus !== "" && _.find(this.statusList, (item: any) => item.id === this.dispatchStatus.CustomStatus)) {
            this.selectedStatus = this.dispatchStatus.CustomStatus;
        }

    }

    public initDropDownList(): void {
        const dropdownStatus: any = jQuery("#ddlStatus");
        dropdownStatus.select2({
            minimumResultsForSearch: defaultService.getMinimumResultsForSearch(),
            placeholder: this.statusPlaceHolder,
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: this.statusList

        }).on("change", (e: any) => {
            this.selectedStatus = e.target.value;
        }).val(this.selectedStatus).trigger("change");
    }

    public async save(): Promise<void> {
        if (!this.statusDisabled && this.selectedStatus === "") {
            notificationHelper.showWarning(this.i18n.tr("err_StatusRequired"));
        } else {
            await serviceService.setStatus(this.serviceType, this.dispatchId, this.selectedStatus);
            routerHelper.navigateBack();
        }

        if (this.serviceType === this.serviceTypeEnum.serviceCall) {
            await this.serviceCallDispatchOrderService.UpdateOrderNo(this.dispatchId, this.informations.OrderNumber);
        }
     }

}
