import templateService from "services/templateService";
import serviceService from "services/serviceService";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import dateHelper from "helpers/dateHelper";
import EmailHelper from "helpers/emailHelper";
import labelHelper from "helpers/labelHelper";
import contactService from "services/contactService";
import dispatchService from "services/dispatchService";
import { CallSummaryModel } from "api/models/company/service/call-summary-model";
import { ServiceCallEquipmentDetailModel } from "api/models/company/service/service-call-equipment-detail-model";
import _ from "underscore";
import { ServiceCallContractEquipmentStatusModel } from "api/models/company/service/service-call-contract-equipment-status-model";
import { ServiceCallNoteModel } from "api/models/company/service/service-call-note-model";
import { CallModel } from "api/models/company/service/call-model";
import { ServiceCallMaterialModel } from "api/models/company/service/service-call-material-model";
import { ServiceCallStatusModel } from "api/models/company/service/service-call-status-model";
import { DispatchStatusModel } from "api/models/company/dispatch/dispatch-status-model";
import val from "core/val";
import notificationHelper from "helpers/notificationHelper";

@autoinject()
export class ServiceSignature {

    //#region Properties
    public val: typeof val = val;

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public notificationHelper: typeof notificationHelper = notificationHelper;

    public client: any = {};
    public contactNameAvailable: boolean = true;
    public contacts: any[] = [];
    public dispatch: CallModel | null = null;
    public id: any = "";
    public assignedDate: any = "";
    public fromHistory: boolean = false;
    public equipmentCode: any = "";
    public dispatchId: any;
    public emailAvailable: boolean = false;
    public equipment: any[] = [];
    public equipmentDetail: any = {};
    public infos: any = {};
    public inputBag: any = {};
    public labor: any = {};
    public lblCompletedStatus: any = "";
    public lblFollowUpStatus: any = "";
    public material: any[] = [];
    public manageTravelTime: boolean = false;
    public notes: Array<ServiceCallNoteModel | null> | null = null;
    public serviceType: any = "";
    public signatureAvailable: boolean = false;
    public status: any = {};
    public viewbagValid: boolean = false;
    public oneDayWorkDuration: boolean = false;
    public noCallFound: boolean = false;
    public isClosed: boolean = false;
    public specifiedLanguage: string| null = "";
    public clientLanguage: string | null = "";
    public email: string | null = "";
    public txtSignature: string = "";

    public internationalization: { lng: string} = { lng: "fr"};

    public viewbag: DispatchStatusModel | any = {
        IsTechWillReturn: false,
        IsClosed: false,
        CustomStatus: "",
        CustomStatusDescription: "",
        Date: new Date(),
        Hour: "", Length: 0,
        Comment: "",
        Workorder: "",
        OrderNumber: "",
        DayStartTime: "",
        IsLead: false,
        IsMultiVisitForm: false,
        IsLastVisit: false,
        localizationModel: null,
        Signature: null,
        IsComplete: false
    };

    public onSignatureCompletedAction!: () => any;
    public selectedContact: any = null;

    constructor(private readonly i18n: I18N, private readonly emailHelper: EmailHelper) {

    }

    public attached(): any {
        routerHelper.hideLoading();
    }

    public async activate(params: any): Promise<any> {
        if (params.id && !params.q) {
            // TODO: Pourquoi on ferait ça ???
            params.q = params.id;
            params.id = null;
        }

        this.bindViewModel(params.serviceType, params.dispatchId, params.id, params.q);
        await this.loadData();
    }

    public bindViewModel(serviceType: string, dispatchId: string, id: number, querystring: string): void {
        this.clear();

        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.id = "";
        this.assignedDate = "";
        this.equipmentCode = "";

        this.getQuerystringData(querystring);

        this.validateInput();

        this.onSignatureCompletedAction = this.onSignatureCompleted.bind(this);
    }

    public validateInput(): void {
        if (this.viewbag &&
            this.viewbag !== undefined &&
            this.viewbag.DispatchId !== undefined &&
            this.viewbag.ServiceType !== undefined &&
            this.viewbag.Date !== undefined &&
            this.viewbag.Comment !== undefined &&
            this.viewbag.CustomStatus !== undefined &&
            this.viewbag.Hour !== undefined &&
            this.viewbag.IsComplete !== undefined &&
            this.viewbag.IsTechWillReturn !== undefined &&
            this.viewbag.Length !== undefined &&
            this.viewbag.Workorder !== undefined &&
            this.viewbag.EndDate !== undefined &&
            this.viewbag.IsClosed !== undefined &&
            this.viewbag.DispatchId === this.dispatchId &&
            this.viewbag.ServiceType === this.serviceType) {
            //valid
            this.inputBag = this.viewbag;
            this.viewbagValid = true;
        } else {
            //invalid
            this.inputBag = {};
            this.viewbagValid = false;
        }

        //clear viewbag to fix back issue where the user could come back without respecting the flow
        this.viewbag = undefined;
    }

    public clear(): void {
        this.viewbag = {
            IsTechWillReturn: false,
            IsClosed: false,
            CustomStatus: "",
            CustomStatusDescription: "",
            Date: new Date(),
            Hour: "", Length: 0,
            Comment: "",
            Workorder: "",
            OrderNumber: "",
            DayStartTime: "",
            IsLead: false,
            IsMultiVisitForm: false,
            IsLastVisit: false,
            localizationModel: null,
            Signature: null,
            IsComplete: false
        };
    }

    public async loadData(): Promise<void> {
        this.initClient(await dispatchService.getClient(this.dispatchId));

        let summaryPromise: CallSummaryModel;
        if (this.id && this.fromHistory) {
            summaryPromise = await serviceService.getSummaryFromId(this.serviceType, this.id, this.assignedDate, this.specifiedLanguage!);
        } else {
            summaryPromise = await serviceService.getSummaryFromDispatchId(this.serviceType, this.dispatchId, this.specifiedLanguage!, this.fromHistory);
        }

        let equipmentDetailPromise: ServiceCallEquipmentDetailModel | null = null;
        if (this.equipmentCode) {
            equipmentDetailPromise = await serviceService.getEquipmentDetail(this.serviceType, this.id, this.equipmentCode);
        }

        const dispatchTemplatePromise = await templateService.getCurrentDispatchTemplateConfigs();

        this.initialise(await summaryPromise, await equipmentDetailPromise, await dispatchTemplatePromise);
    }

    public initialise(summary: CallSummaryModel, equipmentDetail: ServiceCallEquipmentDetailModel | null, dispatchTemplate: any): void {
        const equipment = _.filter(summary.Equipments!, (equ: ServiceCallContractEquipmentStatusModel ) => equ.IsUsed);

        this.noCallFound = summary.NoVisitFound;

        this.notes = summary.Notes;
        this.labor = summary.Labors == null ? [] : summary.Labors;
        this.equipment = equipment;
        this.client = summary.Client;
        this.equipmentDetail = equipmentDetail;

        this.manageTravelTime = dispatchTemplate.ManageTravelTime;

        if (summary.Status) {
            this.specifiedLanguage = !summary.Status.IsClosed ? this.clientLanguage : null;
        } else {
            this.specifiedLanguage =  this.clientLanguage;
        }

        this.internationalization.lng = this.specifiedLanguage ? this.specifiedLanguage : this.internationalization.lng;

        this.initStatus(summary.Status);

        if (this.viewbagValid && this.inputBag.OrderNumber) {
            summary.Detail!.OrderNumber = this.inputBag.OrderNumber;
        }

        this.initDispatch(summary.Detail!);

        this.initSignature();

        this.initMaterials(summary.Materials!);
    }

    public initClient(client: any): void {
        this.clientLanguage = (client && client.Language) ? labelHelper.getLanguageAcronym(client.Language) : null;
        this.specifiedLanguage = !this.isClosed ? this.clientLanguage : null;
    }

    public initDispatch(dispatch: CallModel): void {
        this.dispatch = dispatch;

        if (!this.dispatch) { return; }

        if (this.dispatch.AssignedEndDate) {
            this.oneDayWorkDuration = !dateHelper.isAfter(this.dispatch.AssignedEndDate, this.dispatch.AssignedDate!);
        } else {
            this.oneDayWorkDuration = true;
        }
        if (this.status) {
            if (this.dispatch.ServiceType === "S") {
                this.lblCompletedStatus = this.status.IsComplete || (this.viewbagValid && this.inputBag.IsComplete)
                    ? this.i18n.tr("ServiceCallCompleted", this.specifiedLanguage)
                    : this.i18n.tr("ServiceNotCompleted", this.specifiedLanguage);
            } else {
                this.lblCompletedStatus = this.status.IsComplete || (this.viewbagValid && this.inputBag.IsComplete)
                    ? this.i18n.tr("WorkOrderCompleted", this.specifiedLanguage)
                    : this.i18n.tr("WorkOrderNotCompleted", this.specifiedLanguage);
            }
        }

        if (this.dispatch.ContactDetails) {
            this.email = this.dispatch.ContactDetails.Email;
            this.txtSignature = this.dispatch.ContactDetails.FirstName + " " + this.dispatch.ContactDetails.Name;

            this.selectedContact = {text: this.txtSignature, data: {data: this.email}};
        }
    }

    public initMaterials(material: Array<ServiceCallMaterialModel | null>): void {
        this.material = [
            { title: this.i18n.tr("Material", this.specifiedLanguage), items:  _.where(material, { IsBillingItem: false }) },
            { title: this.i18n.tr("BillingItem", this.specifiedLanguage), items: _.where(material, { IsBillingItem: true }) }
        ];

        this.material = _.filter(this.material, (mat: any) => mat.items.length > 0);
    }

    public initStatus(status: ServiceCallStatusModel | null): void {
        this.status = status;
        if (status) {
            if (this.status.IsTechWillReturn) {
                this.lblFollowUpStatus = `${this.i18n.tr("TechnicianWillBeBackOn", this.specifiedLanguage)} ${dateHelper.getFullTextDate(this.status.Date, this.specifiedLanguage)} ${this.i18n.tr("for", this.specifiedLanguage)} ${this.status.Length} h.`;
            } else {
                this.lblFollowUpStatus = this.i18n.tr("TechnicianFollupNoScheduled", this.specifiedLanguage);
            }
        }
    }

    public initSignature(): void {
        if (this.viewbagValid) {
            this.signatureAvailable = !this.status.IsClosed && (!this.client || this.client.MobilitySignatureRequiredness !== 2);

            if (this.signatureAvailable && (this.client && this.client.MobilityEmailAvailability !== undefined)) {
                if (this.client.MobilityEmailAvailability === 0) {
                    this.emailAvailable = this.serviceType === "W" ? this.client.GeneralConfigSaveFormWorkorder : this.client.GeneralConfigSaveFormServiceCall;
                } else {
                    this.emailAvailable = this.client.MobilityEmailAvailability === 1;
                }
            } else if (this.dispatch && this.dispatch.Contract && this.dispatch.Contract.IsMiscCustomer) {
                this.emailAvailable = true;
            } else {
                this.emailAvailable = false;
            }

            this.initStatus(this.inputBag);

        } else {
            this.signatureAvailable = false;
        }
    }

    public get loadContacts(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                contactService.getByCustomerCode(this.client.Id, params.data.filter, params.data.page || 1)
                    .done(success)
                    .fail(failure);
            },
            mapResults: (item: any): any => {
                return { id: item.No, text: item.Name + ", " + item.FirstName, data: item.Email };
            }
        };
    }

    public getQuerystringData(querystring: string): void {
        const json = routerHelper.getQuerystring(querystring);

        if (!json) { return; }

        if (json.id) {
            this.id = decodeURIComponent(json.id);
        }
        if (json.data) {
            this.viewbag = JSON.parse(json.data);
        }
        if (json.assignedDate && json.assignedDate !== "null" && json.assignedDate !== "undefined") {
            this.assignedDate = decodeURIComponent(json.assignedDate);
        } else {
            this.assignedDate = null;
        }

        if (json.fromHistory) {
            if (decodeURIComponent(json.fromHistory) === "true") {
                this.fromHistory = true;
            } else {
                this.fromHistory = false;
            }

        }

        if (json.equipmentCode) {
            this.equipmentCode = decodeURIComponent(json.equipmentCode);
        }

        if (json.isClosed) {
            this.isClosed = !(json.isClosed === "false") || false;
        } else {
            this.isClosed = false;
        }
    }

    public async onSignatureCompleted(event: any): Promise<void> {
        const signatureData = event.detail;

        if (!this.txtSignature || this.txtSignature.length === 0) {
            notificationHelper.showError(this.i18n.tr("err_SignatureRequired"), "", { timeOut: 0 });
        } else {
            this.inputBag.txtSignature = this.txtSignature;
            this.inputBag.email = this.email ? this.email : "";

            this.inputBag.Signature = {
                Comment: this.txtSignature.replace(" ", " "),
                Email: this.email ? this.email.replace(" ", " ") : "",
                FileData: signatureData
            };

            this.viewbag = this.inputBag;

            await dispatchService.setServiceCallFinish(this.dispatchId, dateHelper.formatDateToSend(this.dispatch!.AssignedEndDate), this.inputBag);
            dispatchService.printform(this.dispatchId, this.inputBag.email);
            routerHelper.navigateBack();
        }
    }

    public showEquipmentDetail(): void {
        return this.equipmentDetail && (this.equipmentDetail.Extension ||
            this.equipmentDetail.PONumber ||
            this.equipmentDetail.ServedSector ||
            this.equipmentDetail.UnitType ||
            this.equipmentDetail.WarrantyEquipmentEndDate ||
            this.equipmentDetail.WarrantyLaborEndDate ||
            this.equipmentDetail.WarrantyStartDate);

    }
}
