import { default as queryStringHelper } from "helpers/queryStringHelper";
define([
    "knockout",
    "helpers/routerHelper",
    "services/materialRequisitionService",
    "widgets/pages/material_requisition",
], function (ko, routerHelper, materialRequisitionService) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.workOrderId = ko.observable();
            self.isProjectResponsible = false;
            self.readonly = false;
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.workOrderId(params.workOrderId);

            self.actions = {
                getRequisitions: materialRequisitionService.getMaterialRequisitionsForFieldService.bind(self, self.workOrderId())
            };

            var paramsQs = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false

            if (paramsQs && paramsQs.readonly) {
                self.readonly = queryStringHelper.parseReadonly(params.q);
            }

        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
