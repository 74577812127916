import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import documentService from "services/documentService";

@autoinject()
export class ServiceRecommendationEditDocumentAdd {

    public dispatchId: number = 0;
    public editId: number = 0;
    public readonly: boolean = false;
    public serviceType: string = "";
    public actions: any;

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.editId = params.recommendationId;
        this.dispatchId = params.dispatchId;
        this.serviceType = params.serviceType;

        this.actions = {
            save: documentService.saveRecommendationPictureForDispatch.bind(this, this.dispatchId, params.recommendationId, (params.serviceType === "W" ? "workorder" : "serviceCall"), paramsQs.entityId)
        };
    }
}
