import { Redirect } from "aurelia-router";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

define([
    "knockout",
    "core/resx",
    "helpers/routerHelper",
    "helpers/dateHelper",
    "helpers/settingHelper",
    "helpers/notificationHelper",
    "services/projectService",
    "services/dailyEntryService",
    "services/defaultService",
    "moment",
    "knockout.validation"
], function (ko, resx, routerHelper, dateHelper, settingHelper, notificationHelper, projectService, dailyEntryService, defaultService, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [I18N, DispatchProjectService, notificationHelper];

        function ctor(i18n, dispatchProjectService, notificationHelper) {
            self = this;
            //#region Properties
            self.resx = resx;
            self.isProjectResponsible = false;
            self.dispatchProjectCode = ko.observable("");
            self.templateCode = "";
            self.i18n = i18n;
            self.dispatchProjectService = dispatchProjectService;
            self.notificationHelper = notificationHelper;

            self.validatedForm = ko.validatedObservable({
                startTime: ko.observable(null),
                endTime: ko.observable(null),
                init: function() {
                    var validatedFormSelf = this;

                    validatedFormSelf.startTime = validatedFormSelf.startTime.extend({
                        validation: {
                            validator: function(val) {
                                var startTime = moment(validatedFormSelf.startTime());
                                var today = moment().startOf('day');
                                var durationPast = moment.duration(today.diff(startTime));
                                var diffDaysPast = durationPast.asDays();
                                var durationFuture = moment.duration(startTime.diff(today));
                                var diffDaysFuture = durationFuture.asDays();
                                var pastLengthAllow = 7; //5 days back + 2 for week-end 
                                var futureLengthAllow = 10; //8 days future + 2 for week-end 

                                //on saturday we use 5 in past...
                                if (today.day() === 6) {
                                    pastLengthAllow = 5;
                                }

                                //on sunday we use 6 in past...
                                if (today.day() === 0) {
                                    pastLengthAllow = 6;
                                }

                                //on thursday and Friday we use 12 in future...
                                if (today.day() === 4 || today.day() === 5) {
                                    futureLengthAllow = 12;
                                }

                                if (diffDaysPast > pastLengthAllow || diffDaysFuture > futureLengthAllow) {
                                    return false;
                                }

                                return true;
                            },
                            message: resx.localize("err_EntryTodayORWithin5Days")
                        }
                    });
                }
            });
            //#endregion
        }

        //#region Private Functions
        function loadData(dispatchProjectCode, templateCode) {
            return projectService.getProjectWorkSchedule(dispatchProjectCode, templateCode).done(function (data) {
                
                var startDateTime = new Date();
                var endDateTime = new Date();
                startDateTime = dateHelper.addTimeSpan(startDateTime, data.StartHour);
                endDateTime = dateHelper.addTimeSpan(endDateTime, data.EndHour);
                self.validatedForm().startTime(startDateTime);
                self.validatedForm().endTime(endDateTime);
            });
        }
        function mapDataToPost() {
            var startDateTime = dateHelper.formatDate(null, self.validatedForm().startTime());
            var tempEndDateTime = dateHelper.formatDate(null, self.validatedForm().startTime());
            var endDateTime = dateHelper.formatDate(null, self.validatedForm().endTime());

            tempEndDateTime.setHours(endDateTime.getHours());
            tempEndDateTime.setMinutes(endDateTime.getMinutes());
            tempEndDateTime.setSeconds(endDateTime.getSeconds());

            if (tempEndDateTime <= startDateTime) {
                tempEndDateTime.setDate(tempEndDateTime.getDate() + 1);
            }

            return {
                templateCode: self.templateCode,
                endTime: dateHelper.formatDateToSend(tempEndDateTime),
                projectId: self.dispatchProjectCode(),
                startTime: dateHelper.formatDateToSend(startDateTime)
            };
        }

        function navigateToNext() {
            routerHelper.navigate(
                        routerHelper.navigateTo("Project_Detail_Daily_Entry_Existing", self.dispatchProjectCode())
                        + routerHelper.addQuerystring({
                            isProjectResponsible: self.isProjectResponsible,
                            dailyEntryDate: dateHelper.dateFromUTC(self.validatedForm().startTime(), "YYYY-MM-DD")
                        })
                        );
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.canActivate = function () {
            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                return new Redirect("Settings");
            }

            return true;
        }

        ctor.prototype.activate = function (params) {
            var paramsQueryString = routerHelper.getQuerystring(params.q);

            self.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
            self.dispatchProjectCode(params.dispatchProjectCode);
            self.validatedForm().init();

            if (!self.isProjectResponsible) {
                routerHelper.navigateBack();
                return false;
            }

            self.templateCode = settingHelper.getSelectedDispatchModel();
            return loadData(params.dispatchProjectCode, self.templateCode);
        };

        ctor.prototype.save = function () {
            var data = mapDataToPost();
            if (self.validatedForm.isValid()) {
                dailyEntryService.checkExistingDailyEntry(self.dispatchProjectCode(), dateHelper.dateFromUTC(self.validatedForm().startTime(), "YYYY-MM-DD"))
                    .done(function () {
                        routerHelper.showLoading();

                        dailyEntryService.addEntry(self.dispatchProjectCode(), data).done(function () {
                            routerHelper.navigate(
                                routerHelper.navigateTo("Project_Detail_Daily_Entry",
                                    self.dispatchProjectCode(),
                                    dateHelper.dateFromUTC(self.validatedForm().startTime(), "YYYY-MM-DD")
                                ) + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible }),
                                {
                                    replace: true,
                                    trigger: true
                                });
                        }).always(function () {
                            routerHelper.hideLoading();
                        });
                    });
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }

        };

        ctor.prototype.copyNewDailyEntry = function () {

            if (self.validatedForm.isValid()) {
                dailyEntryService.checkExistingDailyEntry(self.dispatchProjectCode(), dateHelper.dateFromUTC(self.validatedForm().startTime(), "YYYY-MM-DD"))
                    .done(function () { navigateToNext(); });
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.canActivate = async function (params) {
            self.dispatchProjectCode(params.dispatchProjectCode);

            try {
                await self.checkIsProjectActive();
            } catch (e) {
                if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                    self.notificationHelper.showError(self.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
                } else {
                    self.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
                }
                return false;
            }

        };

        ctor.prototype.checkIsProjectActive = async function () {
            await self.dispatchProjectService.GetProjectFromDispatchProjectCode(self.dispatchProjectCode());
        }

        //#endregion
        return ctor;
    })();

    return viewModel;
});
