import notificationHelper from "helpers/notificationHelper";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import supplierService from "services/supplierService";
import Parse from "helpers/parse";

@autoinject
export class Material {

    @bindable public readonly: boolean = false;
    @bindable public serviceType: string = "";
    @bindable public showMaterialBillButton: boolean = false;

    @bindable public actions: any;

    public isReserved: boolean = false;
    public reservedDisable: boolean = false;
    public showAddOrderButton: boolean = false;
    public ReserveRequiredMaterialForCallReturns: boolean = false;
    public selectedSupplier: any;

    public list: any;

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<void> {
        await this.loadData();
        await this.setReserved();
    }

    public async loadData(): Promise<void> {
        await this.actions.getMaterials()
            .then((data: any): any => {
                this.list = data;
            });
    }

    public async setReserved(): Promise<void> {
        if (this.actions.getReserved) {
            await this.actions.getReserved()
                .then((data: any): any => {
                    //we now show button even if some reserved items has been used
                    this.isReserved = data.ReservedItemsCount > 0;
                    this.reservedDisable = data.AllBalanceZero;
                    this.showAddOrderButton = data.PurchaseOrdersEnabled;
                    this.ReserveRequiredMaterialForCallReturns = data.ReserveRequiredMaterialForCallReturns;
                });
        } else {
            this.isReserved = false;
        }
    }

    public async addReserved(): Promise<void> {
        await this.actions.setReservedMaterials().then((data: any) => {
            this.list = data;
            this.setReserved();
        });
    }

    public async delete(item: any): Promise<void> {
        notificationHelper.showDeleteConfirmation().then(async (success: boolean) => {
            if (success) {
                await this.actions.deleteMaterial(item.LineNo, item.IsBillingItem, item.Timestamp).then((data: any) => {
                    this.list = data;
                    this.setReserved();
                });

                this.loadData();
            }
        });
    }

    public gotoPage(material: any, isBillingItem: any): void {
        if (this.actions.goToPage) {
            this.actions.goToPage(material);
        } else {
            const url = (material && material.LineNo)
                ? routerHelper.getRelativeUrl("edit", material.IsBillingItem, material.LineNo) :
                routerHelper.getRelativeUrl("edit", Parse.Boolean(isBillingItem), -1);

            routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: this.readonly }));
        }
    }

    public get lookupSupplier(): any {
         return {
            transport: (params: any, success: any, failure: any): any => {
                supplierService.getSupplierForCompany(params.data.filter, params.data.page || 1).done(success)
                    .fail(failure);
            },
            mapResults: (item: any): any => {
                return { id: item.Code, text: item.Code + " - " + item.Name, data: item };
            }
        };
    }

    public gotoPageMaterialNoBill(material: any): void {
        this.gotoPage(material, false);
    }

    public gotoPageMaterialBill(material: any): void {
        this.gotoPage(material, true);
    }

    public async createOrder(selectedSupplier: any): Promise<void> {
        await this.actions.createPurchaseOrder(selectedSupplier.id)
            .then((orderNumber: number) => {
                notificationHelper.showSuccess(this.i18n.tr("msg_PurchaseOrderCreationSuccess").replace("[No]", orderNumber.toString()));
            });
    }
}
