import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-framework";
import { DocumentProxy } from "api/proxies/document-proxy";

@autoinject
export class ReceiptOfGoodsDocumentAdd {

    public readonly: boolean = false;
    public receiptId: number | null = null;
    public dispatchTemplateId: string | null = null;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;
    
    public actions: any;

    constructor(
        private readonly documentProxy: DocumentProxy,
        private readonly dispatchTemplateService: DispatchTemplateService
    ) { }

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.receiptId = params.receiptId;
        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        this.actions = { save: documentService.saveReceiptPicture.bind(self, this.receiptId, this.dispatchTemplateId) };
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(this.dispatchTemplateId!);
        if (dispatchTemplate !== null) {
            this.isCategorySelectionAllowed = dispatchTemplate.AllowCategorySelection;
            this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, dispatchTemplate.Code);
        }
    }
}
