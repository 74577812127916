import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import dispatchService from "services/dispatchService";
import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";

@autoinject()
export class ServiceComment {

    public btnText: string = "";
    public comment: string = "";
    public dispatchId: number = 0;
    public id: string = "";
    public serviceType: string = "";
    public readonly: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<void> {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
        await this.loadData();
    }

    public bindViewModel(serviceType: string, dispatchId: number, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public async setComment(): Promise<void> {
        await dispatchService.setComment(this.dispatchId, this.comment)
            .then(() => {
                routerHelper.navigateBack();
            });
    }

    public async loadData(): Promise<void> {
        this.comment = await dispatchService.getComment(this.dispatchId);
        this.btnText = this.i18n.tr("Save");
    }
}
