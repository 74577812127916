import jQuery from "jquery";
import { default as resx } from "core/resx";
import { default as moment } from "moment";
import { default as enumHelper } from "helpers/enumHelper";
import { inject, computedFrom, observable } from "aurelia-framework";

import "widgets/pageBottom";
import "select2";

import RouteRepository from "repositories/routeRepository";

import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as stringHelper } from "helpers/stringHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { LocationType, nameof as nameof_LocationType } from "api/enums/location-type";

import { LocationService } from "services/location-service";
import { PurchaseOrderService } from "services/purchase-order-service";
import { DispatchProjectService } from "services/dispatch-project-service";
import { ServiceCallService } from "services/service-call-service";
import { default as supplierService } from "services/supplierService";
import { default as purchaseOrderService } from "services/purchaseOrderService";

import UserSettingService from "services/userSettingsService";
import Parse from "helpers/parse";
import { ServiceDispatchType } from "enums/service-dispatch-type";

    @inject(LocationType, LocationService, DispatchProjectService, ServiceCallService , PurchaseOrderService, RouteRepository)
    export class MaterialOrderEdit {

    @observable dateEntered;
    @observable orderDate;
    @observable isNew;
    @observable readonly;
    @observable purchaseOrder = {};
    @observable orderId;
    @observable extension;
    @observable status;
    @observable selectedSupplier;

    @observable dispatchProjectCode = "";
    @observable dispatchDate = "";
    @observable projectCode = "";
    @observable serviceType = "";

    @observable isProject = false;
    @observable isReceptionImplicit = true;

    @observable canTransfer = false;
    @observable allowedSendTransfer = false;

    @observable requireSave = false;

    acceptedTypes = [];

    @computedFrom("orderDate")
    get addUrl() {
        const date = dateHelper.dateToNavigationParameter(this.orderDate);
        let temp = this.routerHelper.getRelativeUrl('');

        if (stringHelper.endsWith(temp, "edit")) {
            temp += "/"
            temp += this.orderId;
            temp += "/item/";
            temp += date;

            return temp;
        }
        if (this.projectCode) {
            return this.routerHelper.getRelativeUrl('item', date) + this.routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension});
        }

        return this.routerHelper.getRelativeUrl('item', date) + this.routerHelper.addQuerystring({ readonly: this.readonly, extension: this.extension });
    }

    @computedFrom("isNew")
    get btnText() {
        return this.isNew ? resx.localize("Add") : resx.localize("Save");
    }

    @computedFrom("isNew")
    get purchaseOrderIdDisplay() {
        return this.isNew ? resx.localize('New_F') : this.orderId;
    }

    @computedFrom("purchaseOrder.Items")
    get ordItemsVisible() {
        return !this.isNew && this.purchaseOrder.Items && this.purchaseOrder.Items.length > 0;
    }

    @computedFrom("isNew")
    get txtNoOrderItem() {
        return this.isNew ? resx.localize('lstNoData_PurchaseOrderItemUnsaved') : resx.localize('lstNoData_RequisitionItem');
    }

    constructor(locationType, locationService, dispatchProjectService, serviceCallService, purchaseOrderService, routeRepository, userSettingService) {
        this.resx = resx;
        this.dateHelper = dateHelper;
        this.routerHelper = routerHelper;

        this.actions = {};
        this.lookupSupplier = {
            transport: (params, success, failure) => {
                supplierService.getSupplierForCompany(params.data.filter, params.data.page || 1).done(success)
                    .fail(failure);
            },
            mapResults: (item) => {
                return { id: item.Code, text: item.Code + ' - ' + item.Name, data: item };
            }
        };

        this.locationType = locationType;
        this.locationService = locationService;
        this.dispatchProjectService = dispatchProjectService;
        this.serviceCallService = serviceCallService;
        this.purchaseOrderService = purchaseOrderService;
        this.routeRepository = routeRepository;
    }

    //#region Private Methods
    async bindViewModel(params) {
        this.actions = params.actions;
        this.orderId = params.orderId ? params.orderId : "-1";
        this.extension = params.extension ? params.extension : "";

        if (params.orderId === "-1") {
            this.readonly = false;
        } else {
            this.readonly = params.readonly;
        }

        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchDate = params.dispatchDate;
        this.projectCode = params.projectCode;
        this.dispatchId = params.dispatchId;
        this.serviceType = params.serviceType;
        this.workOrderId = params.workOrderId;

        this.documentUrl = params.documentUrl;
       
        const queryString = routerHelper.addQuerystring({
                dispatchProjectDate: this.dispatchDate,
                orderId: this.orderId,
                readonly: this.readonly,
                extension: this.extension
        });

        this.memoUrl = `${routerHelper.getRelativeUrl("memo")}${queryString}`;

        this.isProject = params.isProject;

        this.acceptedTypes = this.isProject ? [this.locationType.Alternative, this.locationType.Customer, this.locationType.Project, this.locationType.Company, this.locationType.Supplier, this.locationType.SupplierSite, this.locationType.List] : [this.locationType.Alternative, this.locationType.Customer, this.locationType.Project, this.locationType.Contact, this.locationType.List];
    }

    loadData() {
        const deferred = new jQuery.Deferred();
        this.actions.getPurchaseOrder().done(async (data) => {
            this.purchaseOrder = data;

            if (!this.purchaseOrder.CreatedByCurrentUser && this.purchaseOrder.Id !== null) {
                this.readonly = true;
            }
           
            //Init values for new order
            if (this.purchaseOrder.Id === null) {
                await this.initValuesForNewRequisition();
            } else {
                this.isNew = false;
                this.isReceptionImplicit = this.purchaseOrder.AutomaticReceipt;
            }

            var allowdsdtrf = await this.purchaseOrderService.AllowedSendTransfer();
            this.allowedSendTransfer = allowdsdtrf;

            if (this.isNew) {
                if (this.isProject) {
                    this.purchaseOrder.DeliveryAddress = (await this.dispatchProjectService.getProjectDeliveryAddress(this.dispatchProjectCode, this.locationType.Project))[0];
                } else {
                    this.purchaseOrder.DeliveryAddress = (await this.serviceCallService.GetDeliveryAddress(this.dispatchId, this.locationType.Customer))[0];
                }
            }

            this.canTransfer = !this.isNew && this.purchaseOrder.Status === enumHelper.purchaseOrderStatus.RESERVED && this.purchaseOrder.CreatedByCurrentUser;

            this.updateself();

            const queryString = routerHelper.addQuerystring({
                dispatchProjectDate: this.dispatchDate,
                orderId: this.orderId,
                readonly: this.readonly,
                extension: this.extension
            });

            this.additionalFieldsUrl = `${routerHelper.getRelativeUrl("additionalFields")}${queryString}`;

            deferred.resolve();

        }).fail(() => { deferred.reject; routerHelper.navigateBack(); });

        return deferred;
    }

    async initValuesForNewRequisition() {
        this.isNew = true;
        this.purchaseOrder.Status = enumHelper.purchaseOrderStatus.RESERVED;
        this.purchaseOrder.OrderDate = dateHelper.getVeryShortDateLocal(moment());
        this.purchaseOrder.UserNo = -1;
        this.purchaseOrder.Items = [];

        const usrSettingReceptionImplicit = await UserSettingService.getReceptionImplicitSetting();
        if (usrSettingReceptionImplicit) {
            this.isReceptionImplicit = Parse.Boolean(usrSettingReceptionImplicit.Value);
        }

        this.purchaseOrder.AutomaticReceipt = this.isReceptionImplicit;
    }

    updateself() {
        this.orderDate = this.purchaseOrder.OrderDate;

        if (this.purchaseOrder.SupplierCode !== null) {
            this.selectedSupplier = { id: this.purchaseOrder.SupplierCode, text: this.purchaseOrder.SupplierCode + " - " + this.purchaseOrder.Supplier };
        }

        this.readonly = this.readonly || this.purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED;
    }

    activate(params) {
        this.bindViewModel(params);
        return this.loadData();
    }

    deleteItem(item) {
        notificationHelper.showDeleteConfirmation().done((success) => {
            if (success) {
                routerHelper.showLoading();
                purchaseOrderService.delPurchaseOrderItem(this.orderId, item.Line, this.extension)
                    .done(() => {
                        this.loadData();
                    }).always(() => {
                        routerHelper.hideLoading();
                    });
            }
        });
    }

    async getAddress(addressType, filter, pagingInfo, requestConfig) {
        if (this.isProject) {
            const prefix = this.purchaseOrder && this.purchaseOrder.Prefix ? this.purchaseOrder.Prefix : null;
            return await this.dispatchProjectService.getProjectDeliveryAddress(this.dispatchProjectCode, addressType, this.selectedSupplier ? this.selectedSupplier.id : null, prefix, this.selectedSupplier ? this.dispatchId : null, this.workOrderId ? this.workOrderId: null, filter, pagingInfo, requestConfig);
        } else {
            return await this.serviceCallService.GetDeliveryAddress(this.dispatchId, addressType, filter, pagingInfo, requestConfig);
        }
    }

    setReceptionImplicit() {
        if (this.readonly) { return; }

        this.isReceptionImplicit = !this.isReceptionImplicit;
        this.purchaseOrder.AutomaticReceipt = this.isReceptionImplicit;
    }

    async sendToSupplier() {
        await this.savePurchaseOrder(true);

        var queryString = routerHelper.buildQueryString({
            isProjectResponsible: this.isProjectResponsible, readonly: true, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, workOrderId: this.workOrderId
        });

        if (this.isProject && this.serviceType !== ServiceDispatchType.Workorder) {
            routerHelper.navigateToRoute(this.routeRepository.routes.Project_MaterialOrder_summary.name, { dispatchProjectCode: this.dispatchProjectCode, orderId: this.orderId, q: queryString });
        } else {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_MaterialOrderSummary.name, { dispatchProjectCode: this.dispatchProjectCode, orderId: this.orderId, serviceType: this.serviceType, dispatchId: this.dispatchId, q: queryString });
        }

    }

    closeOrder() {
        notificationHelper.showConfirmation(resx.localize('msg_CloseOrderConfirmation')).done(async (success) => {
            if (success) {
                await this.savePurchaseOrder(true);
                await this.purchaseOrderService.Transfer(this.purchaseOrder.Id, this.extension);
                routerHelper.navigateBack();
            }
        });
    }

    genUrl(line) {
        return routerHelper.getRelativeUrl('item', dateHelper.dateToNavigationParameter(this.orderDate), line) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension });
        }

    getReceiptOfGoods() {
        routerHelper.navigateToRoute(this.routeRepository.routes.ReceiptOfGoods.name, { orderId: this.orderId, extension: this.extension });
    }

    async onSupplierSelected(supplier) {
        var originalSupplierCode =  this.purchaseOrder.SupplierCode;
        var originalSupplierDesc = this.purchaseOrder.Supplier;
        if(this.purchaseOrder.SupplierCode !== supplier.Code && !!this.purchaseOrder.SupplierCode && this.purchaseOrder.Items.length > 0) {
            notificationHelper.showDialogYesNo(resx.localize("ConfirmUpdatePricesSupplierChanged"))
                .then(async (success) => {
                    if (success) {
                        await this.purchaseOrderService.ChangeSupplier(this.purchaseOrder.Id, supplier.Code, this.extension);
                        this.loadData();
                    } else {
                        this.purchaseOrder.SupplierCode = originalSupplierCode;
                        this.purchaseOrder.Supplier = originalSupplierDesc;

                        this.selectedSupplier = { id: this.purchaseOrder.SupplierCode, text: this.purchaseOrder.SupplierCode + " - " + this.purchaseOrder.Supplier };
                    }
            });
        }
    }

    async savePurchaseOrder(preventNavigate= false) {
        this.purchaseOrder.OrderDate = this.orderDate;

        this.purchaseOrder.Id = this.orderId === "-1" ? null : this.orderId;

        if (!this.isProject) {
            this.purchaseOrder = this.actions.setSpecificFields(this.purchaseOrder);
        }

        routerHelper.showLoading();

        if (!this.selectedSupplier) {
            notificationHelper.showValidationError([resx.localize("err_NoSupplierSelected")]);
            routerHelper.hideLoading();
            return;
        } else {
            this.purchaseOrder.SupplierCode = this.selectedSupplier.id;
        }

        await UserSettingService.setReceptionImplicitSetting(this.isReceptionImplicit);

        const data = await this.actions.savePurchaseOrderService(this.purchaseOrder);
        this.orderId = data.Id;

        if (this.isNew) {
            this.isNew = false;

            //The navigation doesn't work (doesn't trigger activate or other events), but it at least add the parameter in the URL
            routerHelper.navigate(routerHelper.getRelativeUrl(encodeURIComponent(data.Id)) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId }), { replace: true, trigger: true });
        } else {
            if(preventNavigate !== true) {
                routerHelper.navigateBack();
            }
        }

        routerHelper.hideLoading();

    }
}