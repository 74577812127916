import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { autoinject } from "aurelia-framework";

@autoinject
export class ServiceEquipmentDocumentAdd {
    public readonly: boolean = false;
    public dispatchId: string | null = null;
    public actions: any;

    public equipmentCode: any;
    public equipmentId: any;

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchId = params.dispatchId;

        this.equipmentCode = params.equipmentCode;
        this.equipmentId = paramsQueryString.equipmentId;

        this.actions = {
            save: documentService.saveEquipmentPicture.bind(this, params.serviceType, params.dispatchId, paramsQueryString.entityId, params.equipmentCode, paramsQueryString.equipmentId)
        };

    }
}
