import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { EventAggregator } from "aurelia-event-aggregator";
import routerHelper from "helpers/routerHelper";
import additionalFieldsTypeService from "services/additionalFieldsTypeService";

@autoinject
export class ServiceAdditionalFieldsType {
    public actions: any = {};
    public serviceType: string = "";
    public dispatchId: number = 0;
    public workOrderId: string = "";
    public entityId: number = 0;
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.ServiceCall;
    public saveAdditionalFieldsButton: boolean = true;
    public workOrderType: any;

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator) {}

    public activate(params: any): void {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
    }

    public bindViewModel(serviceType: string, dispatchId: number, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.workOrderId = params.entityId;
        this.workOrderType = params.workOrderType;

        this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.workOrderType})`);
    }

    public bind(): void {
        this.actions = {
            getAll: additionalFieldsTypeService.getAllForFieldService.bind(additionalFieldsTypeService, this.workOrderId),
            setAll: additionalFieldsTypeService.setAllForFieldService.bind(additionalFieldsTypeService, this.workOrderId)
        };
    }
}
