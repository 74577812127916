
import _ from "underscore";
import val from "core/val";
import Logger from "core/logger";
import routerHelper from "helpers/routerHelper";
import browserHelper from "helpers/browserHelper";
import { StringHelper } from "helpers/string-helper";
import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom, TemplatingEngine } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { PagingInfo } from "api/paging-info";
import { EventAggregator } from "aurelia-event-aggregator";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { default as notifier } from "helpers/notificationHelper";
import { default as documentHelper } from "helpers/documentHelper";
import { default as defaultService } from "services/defaultService";
import settingRepository from "repositories/settingRepository";
import { DocumentExtensionService } from "services/document-extension-service";

// @ts-ignore TODO: Pas de définitions TS pour ce module
import loadImage, { LoadImageOptions } from "blueimp-load-image";
import { DocumentProxy } from "api/proxies/document-proxy";
import RouteRepository from "repositories/routeRepository";

import Compressor from "compressorjs";

@autoinject()
export class DocumentsAdd {
    public val: any = val;
    public fileType: string = "*";
    public allowedFileExtensions: string | null = "";
    public btnAddLabel: string = this.i18n.tr("SelectPicture/Document");
    @bindable public option: any = "";
    @bindable public entityId: any;
    @bindable public readonly: boolean = false;
    @bindable public isCategorySelectionEnabled: boolean = false;
    @bindable public isPhotoOnly: boolean = false;
    @bindable public equipmentId: string | null = null;
    @bindable public files: any[] = [];
    @bindable public totalFileSize: any = 0;
    @bindable public contextPrefix: any;
    @bindable public selectedCategory: any;
    @bindable public comment: string = "";
    @bindable public actions: any;
    @bindable public getCategoryProxy?: (filter: any, pageInfo: PagingInfo) => Promise<DocumentCategoryModel[] | null>;
    @bindable public dispatchTemplateCode: string | null = null;
    @bindable public maxFilesUploadLimit: number = 0;

    public isEditing: boolean = false;
    public fileIndex: number = 0;
    public editedFile: any;
    public toggleEditing: any;

    public isIOS11WebApp: boolean = false;
    public funcSave: any;
    private uploadIndex: number = 0;
    private pageSize: number = 0;
    private jpegFormat: string = "image/jpeg";

    @computedFrom("totalFileSize")
    public get labelTotalFileSize(): string {
        return this.i18n.tr("msg_FileSizeTotalToTransfer").replace("[fileSize]", documentHelper.formatDocumentSize(this.totalFileSize));
    }

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator, private readonly templatingEngine: TemplatingEngine, private readonly documentProxy: DocumentProxy, private readonly routeRepository: RouteRepository, private readonly documentExtensionService: DocumentExtensionService) {
    }

    public async bind(): Promise<void> {
        this.isIOS11WebApp = browserHelper.isIOS11WebApp();
        this.pageSize = defaultService.getPageSize();

        this.toggleEditing = this.toggleEditingMode.bind(this);
        this.updateTotalSize();

        this.allowedFileExtensions = await this.documentExtensionService.GetExtensions();
       
        if (this.isPhotoOnly) {
            this.fileType = "image/*";
            this.btnAddLabel = this.i18n.tr("TakePicture");
            this.eventAggregator.publish("updateTitle", this.i18n.tr("Pictures"));
        } else {
            if (this.allowedFileExtensions !== null && this.allowedFileExtensions !== "") {
                this.fileType = this.allowedFileExtensions;
            }
            this.eventAggregator.publish("updateTitle", this.i18n.tr("Pictures/Documents"));
        }

        if (!this.getCategoryProxy) {
            this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, settingRepository.getDispatchTemplate());
        }
    }

    public get getCategories(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                const pagingInfo: PagingInfo = {
                    page: params.data.page,
                    pageSize: this.pageSize,
                };
                this.getCategoryProxy!(params.data.filter || "", pagingInfo).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: DocumentCategoryModel): any => {
                const display = `${item.Id} - ${item.Description}`;
                return { id: item.Id, text: display, data: item };
            },
        };
    }

    public calculateFileSize(data: any, format?: string): number {
        const head = format ? `data:${format};base64,` : "data:image/png;base64,";
        return Math.round(((data.length - head.length) * 3) / 4);
    }

    public updateTotalSize(): void {
        this.totalFileSize = _.reduce(
            this.getFileSizeArrayFromSelectedFile(),
            (memo: any, num: any): any => {
                return memo + num;
            },
            0
        );
    }

    public getSelectedFile(): any {
        return _.reject(this.files, (file: any): any => {
            return !file.selected;
        });
    }

    public getFileSizeArrayFromSelectedFile(): any {
        return _.map(this.getSelectedFile(), (file: any): any => {
            return file.fileSize;
        });
    }

    public handleFileSave(nbFiles: any, cpt: any, cptSuccess: any): void {
        if (cpt === nbFiles) {
            if (cptSuccess === nbFiles) {
                routerHelper.navigateBack();
            }
            routerHelper.hideLoading();
        }
    }

    public createImageThumbnail(img: any, index: any, fileSize: any, fileName: any, fileType: any): void {
        const itemWrapper = jQuery("<div>").attr("class", "");
        const imageWrapper: any = jQuery("<div>").attr("class", "img-wrapper fa ");
        const imageVerticalWrapper: any = jQuery("<div>").attr("class", "img-vertical-wrapper ");

        imageWrapper.attr("click.delegate", `toggleImage(${index})`);
        imageWrapper.attr("class.bind", `files[${index}].selected ? 'checked' : ''`);

        let imageElement: any;

        if (img) {
            imageElement = jQuery("<img>").attr("src", img.src || img.toDataURL());
            imageElement.attr("id", `img_${index}`);
            imageElement.attr("class", "picture-upload-thumb");
        } else {
            imageElement = jQuery("<span>").attr("class", "fa " + documentHelper.getIconClass(fileType));
        }

        const fileSizeWrapper = jQuery("<div>").attr("class", "file-size");
        fileSizeWrapper.attr("id", `img_size_${index}`);
        fileSizeWrapper.html(documentHelper.formatDocumentSize(fileSize));

        const editImageWrapper = jQuery("<button>").attr("class", "btn btn-primary mx-auto block");
        editImageWrapper.attr("click.trigger", `editImage(${index})`);

        editImageWrapper.html(this.i18n.tr("EditImage"));

        //TODO VERIFY
        imageVerticalWrapper.html(imageElement);
        imageWrapper.html(imageVerticalWrapper);
        itemWrapper.html(imageWrapper);
        if (fileName) {
            const fileNameWrapper = jQuery("<div>").attr("class", "file-name");
            fileNameWrapper.html(fileName);
            itemWrapper.append(fileNameWrapper);
        }
        itemWrapper.append(fileSizeWrapper);

        if (img) { itemWrapper.append(editImageWrapper); } //Flow d'edition à l'upload à revalider

        jQuery(".picture-container-multiple").append(itemWrapper);

        this.templatingEngine.enhance({ element: imageWrapper.get(0), bindingContext: this });
        this.templatingEngine.enhance({ element: editImageWrapper.get(0)!, bindingContext: this });
    }

    public getFileMisc(file: any): void {
        const index = this.uploadIndex;
        this.uploadIndex++;

        file.fileSize = file.size;
        file.selected = true;

        const reader = new FileReader();
        reader.onload = (): void => {
            file.fileContent = reader.result;
            this.files.push(file);

            this.createImageThumbnail(null, index, file.size, file.name, file.type);
            this.updateTotalSize();
            routerHelper.hideLoading();
        };

        reader.readAsDataURL(file);
    }

    public getImage(file: any): void {
        routerHelper.showLoading();

        const options: LoadImageOptions = {
            maxWidth: 1900,
            maxHeight: 1425,
            canvas: true,
            orientation: undefined,
        };

        const replaceImage = (img: any): void => {
            if (img.type === "error") {
                notifier.showError(this.i18n.tr("err_LoadImageFailed"));
                routerHelper.hideLoading();
            } else if (img.src || img instanceof HTMLCanvasElement) {
                const index = this.uploadIndex;
                this.uploadIndex++;
                const fileSize = this.calculateFileSize(img.toDataURL(this.jpegFormat), this.jpegFormat);

                this.createImageThumbnail(img, index, fileSize, "", "");

                this.files[index].fileSize = fileSize;
                this.files[index].fileContent = img.toDataURL(this.jpegFormat);
                this.updateTotalSize();
                routerHelper.hideLoading();
            }
        };

        loadImage.parseMetaData(file, (data: any): void => {
            if (data.exif) {
                options.orientation = data.exif.get("Orientation");
            }
            if (loadImage(file, replaceImage, options)) {
                file.selected = true;
                this.files.push(file);
            } else {
                jQuery(".picture-container-multiple").html(this.i18n.tr("err_LoadImageNotSupported"));
            }
        });
    }

    public toggleImage(index: any): void {
        this.files[index].selected = !this.files[index].selected;
        this.updateTotalSize();
    }

    public savePicture(): void {
        if (!this.getSelectedFile().length) {
            notifier.showWarning(this.i18n.tr("NoPicture/DocumentSelected"));
        }
        if (this.maxFilesUploadLimit > 0 && this.maxFilesUploadLimit < this.getSelectedFile().length) {
            notifier.showWarning(this.i18n.tr("err_uploadLimitExceeded"));
        } else {
            routerHelper.showLoading();
            const nbFiles = this.getSelectedFile().length;
            let cpt = 0;
            let cptSuccess = 0;

            const filesToSend: any[] = [];
            _.each(this.getSelectedFile(), (file: any): any => {
                const fileModel = {
                    Comment: this.comment || null,
                    FileData: file.fileContent,
                    Type: file.type,
                    OriginalFileName: file.name,
                    Category: this.selectedCategory ? this.selectedCategory.id : null,
                    TemplateCode: null as string | null,
                };

                if (this.dispatchTemplateCode !== null) {
                    fileModel.TemplateCode = this.dispatchTemplateCode;
                } else if (this.option === "fieldservices") {
                    fileModel.TemplateCode = settingRepository.getDispatchTemplate();
                }

                filesToSend.push(fileModel);
                Logger.debug(JSON.stringify(fileModel));
            });

            this.actions
            .save(filesToSend)
            .done(() => {
                cptSuccess += filesToSend.length;
            })
            .always(() => {
                cpt += filesToSend.length;
                this.handleFileSave(nbFiles, cpt, cptSuccess);
            });
        }
    }

    public fileAcquired(ev: any): void {
        const files = ev.target.files;

        _.each(files, async (file: any) => {
            if (file) {
                if (this.isValidFile(file)) {
                    if (StringHelper.startsWith(file.type, "image/")) {
                        const result = await this.compressImage(file);
                        this.getImage(result);
                    } else {
                        this.getFileMisc(file);
                    }

                } else {
                    let warning = this.i18n.tr("Err_DocumentExtensionNotAllowed");
                    warning = warning.replace("{0}", file.name.split(".").pop());
                    notifier.showWarning(warning, "", { timeOut: 0 });
                }
            }
        });

    }

    public editImage(index: number): void {
        if (this.files.length === 0) { return; }

        this.editedFile = this.files[index];
        this.fileIndex = index;
        this.toggleEditingMode();
    }

    public toggleEditingMode(): void {
        this.isEditing = !this.isEditing;
        jQuery(".main").attr("style", "background: #1e1e1e");
        if (!this.isEditing) {
            this.files[this.fileIndex].fileContent = this.editedFile.fileContent;
            const fileSize = this.calculateFileSize(this.editedFile.fileContent, this.jpegFormat);
            this.files[this.fileIndex].fileSize = fileSize;

            jQuery(".main").attr("style", "background: #f8f9fa");
            jQuery(`#img_${this.fileIndex}`).attr("src", this.files[this.fileIndex].fileContent);
            jQuery(`#img_size_${this.fileIndex}`).html(documentHelper.formatDocumentSize(fileSize));
        }
    }

    private isValidFile(file: any): boolean {
        if (this.allowedFileExtensions === null && this.allowedFileExtensions === "") {
            return true;
        }

        const allowedExtensions = this.allowedFileExtensions!.split(",");
        let isValidFile: boolean = false;
        _.each(allowedExtensions, (allowedExtension: any): any => {
            if (file.name.toUpperCase().endsWith(allowedExtension.toUpperCase())) {
                isValidFile = true;
            }
        });
        return isValidFile;
    }

    private async compressImage(file: File): Promise<File> {
        let compressedFile: File;

        compressedFile = await new Promise((resolve: any): void => {
            const options: Compressor.Options = {
                strict: false,
                checkOrientation: false,
                maxWidth: undefined,
                maxHeight: undefined,
                minWidth: 0,
                minHeight: 0,
                width: undefined,
                height: undefined,
                resize: "none",
                quality: 0.6,
                mimeType: "",
                convertTypes: "image/png",
                convertSize: 500000,
                success: resolve,
            };
            const compress = new Compressor(file, options);

        }).then((result: File) => {
            return result;
        });

        return compressedFile;
    }
}
