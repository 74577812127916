import { ValidationHelper } from "helpers/validation-helper";
import { BonusService } from "./../../services/bonus-service";
import commonService from "services/commonService";
import { TradeModel } from "./../../api/models/company/trade-model";
import tradesService from "services/tradesService";
import defaultService from "services/defaultService";
import enumHelper from "helpers/enumHelper";
import dateHelper from "helpers/dateHelper";
import { StringHelper } from "./../../helpers/string-helper";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import val from "core/val";
import { ServiceDispatchType } from "enums/service-dispatch-type";
import notificationHelper from "helpers/notificationHelper";
import serviceService from "services/serviceService";
import { ServiceCallTimesheetService } from "services/service-call-timesheet-service";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { validateTrigger, ValidationController, ValidationRules } from "aurelia-validation";
import moment from "moment";
import _ from "underscore";
import templateService from "services/templateService";
import WageSettingHelper from "helpers/wageSettingHelper";
import queryStringHelper from "helpers/queryStringHelper";
import labelHelper from "helpers/labelHelper";
import { ShiftModel } from "api/models/company/shift/shift-model";
import { BonusConfigModel } from "api/models/company/bonus/bonus-config-model";
import employeeService from "services/employeeService";
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import routerHelper from "helpers/routerHelper";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";
import { BonusModel } from "api/models/company/bonus/bonus-model";

@autoinject
export class ServiceLaborEdit {

    public val: typeof val = val;
    public serviceDispatchType: typeof ServiceDispatchType = ServiceDispatchType;

    public activityToggleModal: boolean = false;
    public employeeToggleModal: boolean = false;

    public btnText: string = "";
    public dispatchId: number = 0;
    public id: number = 0;
    public shiftList: any[] = [];
    public specialEquipment: string = "";
    public readonly: boolean = false;
    public unit: string = "";
    public type: string = "";
    public wage: any[] = [];
    public showBonus: boolean = false;
    public editionMode: boolean = false;
    public displayGroup: boolean = false;
    public isEquipment: boolean = false;

    public showEmployeeSection: boolean = true;
    public showEquipmentSection: boolean = false;

    public hasSeperatedEquipments: boolean = false;
    public serviceType: string = "";

    public activity: any;
    public employee: any;
    public shift: any;
    public equipmentCode: any = null;
    public equipmentActivityCode: any = null;
    public equipmentGroupCode: any = null;
    public workTime: Date = dateHelper.getDate();
    public bonusQty: number | null = null;
    public bonusConfigs: any;
    public bonus: any = null;
    public equipmentWorkTime: Date = dateHelper.getDate();
    public wageType: number = 0;
    public comment: string = "";
    public commentBonus: any = null;
    public group: any = null;
    public bonusDesc: string | null = null;
    public trade: any | null = null;
    public templateConfigs: any;
    public timestamp: Date = new Date();
    public timesheetTotalTime: string = "";
    public estimatedTime: string = "";

    public timeOptions: TimeListItem[] = [];

    @computedFrom("type")
    public get isTimeType(): boolean {
        return this.type !== "labor";
    }

    @computedFrom("type")
    public get isLaborType(): boolean {
        return this.type === "labor";
    }

    constructor(private readonly i18n: I18N, private readonly serviceCallTimesheetService: ServiceCallTimesheetService, private readonly validationController: ValidationController, private validationHelper: ValidationHelper, private readonly bonusService: BonusService) {
    }

    public async activate(params: any): Promise<any> {
        this.initValidation();

        this.unit = "";
        this.bindViewModel(params.serviceType, params.dispatchId, params.lineNo, params.q);

        this.employeeToggleModal = !this.id && this.isLaborType;
        this.activityToggleModal = !this.id && !this.isLaborType;
        await this.loadData();
    }

    public attached(): void {
        this.initDropDownLists();
    }

    public bindViewModel(serviceType: string, dispatchId: number, lineNo: number, querystring: string): any {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.type = queryStringHelper.parse(querystring, "type");
        this.showBonus = false;
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.id = lineNo;
        WageSettingHelper.getDispatchTemplateConfigs("Services", null).then((templateConfigs: any) => {
            this.templateConfigs  = templateConfigs;
            this.wage = WageSettingHelper.getWageFromTemplateconfigs("Services", templateConfigs);
        });
        this.btnText = this.i18n.tr("Save");
        this.editionMode = false;
        this.displayGroup = false;
    }

    public async loadData(): Promise<any> {
        await this.loadShifts();
        // self.validatedForm().init();
        if (!this.editionMode && this.type === "time") {
            this.getCurrentEmployeeDefaultTrade();
        }

        await Promise.all([
            this.loadHeader(),
            this.loadBonusConfig(),
            this.loadLabor(),
            this.allowGroup(),
            this.loadTemplateIncrement()
        ]);

    }

    public onSelectBonus(selectedItem: any): void {
        this.bonusQty = 1;
        this.unit = selectedItem.data.data.Unit;
    }

    public onUnSelectBonus(): void {
        this.unit = "";
    }

    public async onSelectEquipment(item: any): Promise<void> {
        if (this.serviceType === this.serviceDispatchType.Workorder && item.data.DefaultGroupId) {
            const groups = await serviceService.getGroup();

            const defaultGroup = groups.find((x: any) => x.Id === item.data.DefaultGroupId);
            if (defaultGroup) {
                this.equipmentGroupCode = {id: defaultGroup.Id, text: defaultGroup.Id + " - " + defaultGroup.Description};
            }
        }

        if (item.data.DefaultActivityId) {
            const activities = await this.loadActivities();

            const defaultActivity = activities.find((x: any) => x.Id === item.data.DefaultActivityId);
            if (defaultActivity) {
                this.equipmentActivityCode = {id: defaultActivity.Id, text: defaultActivity.Id + " - " + defaultActivity.Description};
            }
        }
    }

    public show(section: string): void {
        this.showBonus = section === "bonus";
    }

    public async allowGroup(): Promise<boolean> {
        if (this.serviceType !== this.serviceDispatchType.Workorder) {
            return false;
        }

        return await templateService.getCurrentDispatchAllowGroup().done((displayGroupValue: boolean) => {
            this.displayGroup = displayGroupValue;
            return displayGroupValue;
        });
    }

    public initialize(data: any): void {
        this.editionMode = true;
        const date = dateHelper.getDate();

        this.timestamp = data.Timestamp;
        this.shift = data.Shift;

        if (this.showEmployeeSection) {
            this.wageType = data.WageType;
            this.comment = data.Comment ? data.Comment : "";
            this.activity = { id: data.ActivityCode, text: data.ActivityCode + " - " + data.ActivityDesc };
            this.employee = { id: data.Employee!.Id, text: data.Employee!.FirstName + " " + data.Employee!.LastName };
        }

        if (this.showEquipmentSection) {
            this.equipmentCode  = {id: data.EquipmentCode, text: data.EquipmentCode + " - " + data.EquipmentDesc};
            this.equipmentActivityCode = {id: data.EquipmentActivityCode, text: data.EquipmentActivityCode + " - " + data.EquipmentActivityDesc};

            if (this.serviceType === this.serviceDispatchType.Workorder) {
                this.equipmentGroupCode = {id: data.EquipmentGroupCode, text: data.EquipmentGroupCode + " - " + data.EquipmentGroupDesc};
            }

            date.setMinutes(Math.round((data.EquipmentWorkTime ? data.EquipmentWorkTime : 0) * 60));
            this.equipmentWorkTime = date;
        }

        this.unit = data.BonusUnit ? data.BonusUnit : "";

        if (data.TradeCode) {
            this.trade = { id: data.TradeCode, text: data.TradeCode + " - " + data.TradeDescription };
        }

        this.group = { id: data.Group, text: data.Group + " - " + data.GroupDescription };
        if (this.serviceType === this.serviceDispatchType.Service) {
            //service call
            if (data.Bonus !== "") {
                this.show("bonus");
                this.bonus = { id: data.Bonus, text: data.Bonus + " - " + data.BonusDesc };
                this.commentBonus = data.Comment;
            } else {
                this.comment = data.Comment ? data.Comment : "";
                this.bonus = null;

            }

            this.bonusDesc = data.BonusDesc;

            if (data.Bonus === "") {
                date.setMinutes(Math.round((data.WorkTime ? data.WorkTime : 0) * 60));
                this.bonusQty = null;
            } else {
                this.bonusQty = data.WorkTime;
            }
        } else {
            //workorder
            date.setMinutes(Math.round((data.WorkTime ? data.WorkTime : 0 ) * 60));
            //set bonus values
            this.initBonusValueWorkOrder(data);
        }

        this.workTime = date;
        this.readonly = this.readonly || data.IsReadonly;
    }

    public setEmployeeDefaultTrade(data: TradeModel): void {
        if (!this.editionMode) {
            this.trade = data ? { id: data.Code, text: data.Code + " - " + data.Description } : null;
        }
    }

    public initBonusValueWorkOrder(data: any): void {
        _.each(data.Bonus, (b: any) => {
            const bonusCfg = _.find(this.bonusConfigs, (bCfg: any) => bCfg.code === b.Code);
            if (bonusCfg.type === 1) {
                bonusCfg.value = b.Value;
            } else {
                bonusCfg.value = b.Value === 1;
            }
            bonusCfg.id = b.Id;
        });
    }

    public initDropDownLists(): void {
        const ddlShift: any = jQuery("#ddlShift");
        ddlShift.select2({
            minimumResultsForSearch: defaultService.getMinimumResultsForSearch(),
            allowClear: false,
            placeholder: this.i18n.tr("SelectOoo"),
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: this.shiftList
        }).on("change", (e: any) => {
            this.shift = e.target.value;
        }).val(this.shift).trigger("change");

    }

    public async loadLabor(): Promise<void> {
        const result  = await this.serviceCallTimesheetService.GetSeperateTimeLinkedEquipmentDispatches(this.serviceType === this.serviceDispatchType.Service, this.dispatchId);
        this.hasSeperatedEquipments = result!.length > 0;

        if (this.id) {
            return serviceService.getLabor(this.serviceType, this.dispatchId, this.id).then((data: any) => {
                this.showEmployeeSection = data.IsEmployee;
                this.showEquipmentSection = data.IsEquipment;
                this.initialize(data);
            });
        } else {
            this.showEquipmentSection = this.hasSeperatedEquipments && !this.isLaborType;
            this.clear();
        }
    }

    public async getEmployeeDefaultTrade(employeeId: number): Promise<void> {
        if (employeeId) {
            await tradesService.getEmployeeDefaultTrade(employeeId).then((result: TradeModel) => this.setEmployeeDefaultTrade(result));
        }
    }

    public async getCurrentEmployeeDefaultTrade(): Promise<void> {
        await tradesService.getCurrentEmployeeDefaultTrade().then((result: TradeModel) => this.setEmployeeDefaultTrade(result));
    }

    public async loadShifts(): Promise<void> {
        if (defaultService.getServiceLastShiftUsed() === undefined || defaultService.getServiceLastShiftUsed() === null) {
            //get the last used value !!
            const lastShift = await serviceService.getLastShiftUsed(this.serviceType);
            if (this.shiftList.length > 0) {
                defaultService.setServiceLastShiftUsed(lastShift);
            }
        }

        let shifList = await commonService.getShifts();
        shifList = _.map(shifList, (shift: ShiftModel) => {
            return { id: shift.Id, text: shift.Id + " - " + shift.Description };
        });

        this.shiftList = shifList;
        const lastShiftUsed = isNaN(defaultService.getServiceLastShiftUsed()) ? 0 : defaultService.getServiceLastShiftUsed();
        const shiftToFind = this.shiftList.find((shiftItem: any) => shiftItem.id === lastShiftUsed);
        if (!shiftToFind && this.shiftList.length > 0) {
            defaultService.setServiceLastShiftUsed(_.first(this.shiftList).id);
        }
    }

    public async loadHeader(): Promise<any> {
        await serviceService.getLaborsHeader(this.serviceType, this.dispatchId).then((data: any) => {
            this.specialEquipment = data.SpecialEquipment;
            this.timesheetTotalTime = dateHelper.hoursTohmm(data.TimesheetTotalTime);
            this.estimatedTime = dateHelper.hoursTohmm(data.EstimatedTime);
        });
    }

    public async loadBonusConfig(): Promise<any> {
        if (this.serviceType === this.serviceDispatchType.Workorder) {
            return await this.bonusService.GetConfig().then((value: BonusConfigModel[] | null) => {
                const list = _.map(value!, (data: BonusConfigModel) => {
                    return {
                        id: 0,
                        code: data.Code,
                        value: null,
                        type: data.ColumnType,
                        description: data.Description,
                        unit: data.Unit
                    };
                });
                this.bonusConfigs = list;
            });
        }
    }

    public async loadBonus(params: any): Promise<BonusModel[] | undefined> {
        if (this.serviceType === this.serviceDispatchType.Service) {
            return (await this.bonusService.GetBonuses(params.data.filter))!;
        }
        return;
    }

    public async loadActivities(params?: any): Promise<any> {
        if (this.serviceType === this.serviceDispatchType.Service) {
            return serviceService.getActivities(this.serviceDispatchType.Service, params ? params.data.filter : "");
        } else {
            return serviceService.getActivitiesByDispatch(this.serviceDispatchType.Workorder, this.dispatchId, enumHelper.workOrderActivityType().LABOUR, params ? params.data.filter : "");
        }
    }

    public async loadEmployee(params: any): Promise<EmployeeModelBase[]> {
        return employeeService.getAvailableEmployeeForTimesheet(this.serviceType, this.dispatchId, defaultService.getCurrentDispatchTemplateCode(), params.data.filter, params.data.page || 1);
    }

    public clear(): void {
            const shiftToUse = defaultService.getServiceLastShiftUsed() || (this.shiftList.length === 0 ? 0 : undefined);
            this.wageType = enumHelper.wageType.SIMPLE;
            this.comment = "";
            this.commentBonus = "";
            this.activity = null;
            this.employee = null;
            this.shift = shiftToUse;
            this.group = null;
            this.bonus = null;
            this.bonusDesc = null;
            this.bonusQty = 0;
            this.trade = null;

            this.equipmentActivityCode = null;
            this.equipmentGroupCode = null;
            this.equipmentWorkTime = dateHelper.getDate();

            this.workTime = dateHelper.getDate();

    }

    public isMinuteAndHoursZero(time: Date): boolean {
        return moment(time).minutes() === 0 && moment(time).hours() === 0;
    }

    public initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
        .ensure("employee").required().when(() => this.isLaborType && this.showEmployeeSection).withMessageKey("SelectAnEmployee")
        .ensure("activity").required().when(() => this.showEmployeeSection).withMessageKey("SelectAnActivity")
        .ensure("workTime").satisfies(() => (!this.isMinuteAndHoursZero(this.workTime) || (this.bonusQty! * 1) > 0)).when(() => this.serviceType === this.serviceDispatchType.Service).withMessageKey("err_TimeSpentOrBonusRequired")
        .then().satisfies(() => !this.isMinuteAndHoursZero(this.workTime)).when(() => this.serviceType === this.serviceDispatchType.Workorder).withMessageKey("err_TimeSpentOrBonusRequired")
        .then().satisfies(() => this.bonusQty! * 1 === 0).when(() => !this.isMinuteAndHoursZero(this.workTime)).withMessageKey("err_TimeSpentOrBonus")
        .then().satisfies(() => this.isMinuteAndHoursZero(this.workTime)).when(() => !!this.bonus).withMessageKey("err_TimeSpentNotWithBonus")
        .ensure("shift").required().when(() => this.showEmployeeSection).withMessageKey("SelectAShift")
        .ensure("bonusQty").satisfies(() => !!this.bonus).when(() => this.bonusQty! * 1 > 0).withMessageKey("err_BonusRequired")
        .then().satisfies(() => this.bonusQty! * 1 > 0 && !!this.bonus).when(() => this.showBonus).withMessageKey("err_BonusQtyRequired")
        .ensure("equipmentActivityCode").required().when(() => this.showEquipmentSection && !!this.equipmentCode).withMessageKey("SelectAnActivity")
        .ensure("equipmentGroupCode").required().when(() => this.showEquipmentSection && !!this.equipmentCode && this.serviceType === this.serviceDispatchType.Workorder).withMessageKey("SelectAGroup")
        .ensure("equipmentWorkTime").satisfies(() => !this.isMinuteAndHoursZero(this.equipmentWorkTime)).when(() => this.showEmployeeSection && this.equipmentCode).withMessageKey("err_TimeSpentRequired")
        .on(this);
    }

    public async loadTemplateIncrement(): Promise<void> {
        const increment = await templateService.getCurrentDispatchIncrement();
        this.timeOptions = TimeListHelper.loadTimeList(Number.parseInt(increment));
    }

    public get getTrades(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                if (this.employee) {
                    tradesService.getTradesForEmployee(this.employee.id, params.data.filter, params.data.page || 1)
                        .then((data: TradeModel[]) => {
                            return success(_.map(data, (x: any) => {
                                x.Id = x.Code;
                                return x;
                            }));
                        });
                } else if (this.type === "time") {
                    tradesService.getTradesForDispatch(this.dispatchId, params.data.filter, params.data.page || 1)
                        .then((data: any) => {
                            return success(_.map(data, (x: any) => {
                                x.Id = x.Code;
                                return x;
                            }));
                        });
                }
            }
        };
    }

    public get lookupActivity(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                await this.loadActivities(params).then(
                (result: any) => {
                    return success(result);
                },
                (fail: any) => {
                    return failure(fail);
                });
            }
        };
    }

    public get lookupEmployee(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                await this.loadEmployee(params).then(
                    (result: any) => {
                        return success(result);
                    },
                    (reason: any) => {
                        if (StringHelper.startsWith(reason.responseJSON.Exception.Message, "Dispatch model not found:")) {
                            notificationHelper.showError(this.i18n.tr("DispatchModel") + " : " + reason.responseJSON.Exception.Message.split(":")[1], "", { timeOut: 0 });
                        }
                    });
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: item.Id + " - " + item.FirstName + " " + item.LastName, data: item };
            }
        };
    }

    public get lookupBonus(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                await this.loadBonus(params).then(
                    (result: BonusModel[] | undefined) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    });
            },
            mapResults: (item: BonusModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
            }
        };
    }

    public get lookupGroup(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                serviceService.getGroup(params.data.filter, params.data.page || 1).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
            }
        };
    }

    public get lookupEquipment(): any {
        return {
            transport: async (params: any): Promise<any> => {
                this.serviceCallTimesheetService.GetSeperateTimeLinkedEquipmentDispatches(this.serviceType === this.serviceDispatchType.Service, this.dispatchId, params.data.filter, params.data.page || 1)
                    .then((result: EquipmentModel[]) => result);
            },
            mapResults: (item: EquipmentModel): any => {
                return { id: item.Code, text: item.Code + " - " + item.Description, data: item };
            }
        };
    }

    public async save(): Promise<void> {

        if (this.showBonus) {
            this.equipmentCode = null;
            this.equipmentActivityCode = null;
            this.equipmentGroupCode = null;
            this.equipmentWorkTime = dateHelper.getDate();
        }

        if (this.shiftList.length > 0 && !this.shift && this.showBonus) {
            this.shift = _.first(this.shiftList).id;
        }

        if (!(await this.validate())) {
            return;
        } else {
            routerHelper.showLoading();

            const workTime = moment(this.workTime).diff(moment(dateHelper.getDate()), "minutes");
            let currentBonus;
            const wageType = this.wageType ? this.wageType : 0;
            const timesheet: any = {
                LineNo: this.id ? this.id : 0,
                Timestamp: this.id ? this.timestamp : undefined,
                ActivityCode: this.showEmployeeSection ? this.activity.id : "",
                TradeCode: this.trade ? this.trade.id : 0
            };
            if (this.serviceType === this.serviceDispatchType.Service) {
                currentBonus = this.bonus === null ? "" : this.bonus.id;
                if (this.showBonus) {
                    //value for P
                    timesheet.WageType = 3;
                    timesheet.WorkTime = this.bonusQty! * 1;
                    timesheet.Comment = this.commentBonus;
                    timesheet.Bonus = currentBonus;
                    if (this.shiftList.length > 0) {
                        timesheet.Shift = _.first(this.shiftList).id;
                    } else {
                        timesheet.Shift = this.shift;
                    }

                } else {
                    timesheet.WageType = wageType;
                    timesheet.WorkTime = workTime > 0 ? workTime / 60 : this.bonusQty! * 1;
                    timesheet.Comment = this.comment;
                    timesheet.Shift = this.shift;
                }
            } else {
                currentBonus = _.map(this.bonusConfigs, (bonusConfig: any) => {
                    return {
                        id: bonusConfig.id,
                        code: bonusConfig.code,
                        value: bonusConfig.type === 1 ? bonusConfig.value : (bonusConfig.value ? 1 : 0),
                        type: bonusConfig.type
                    };
                });

                timesheet.WageType = wageType;
                if (this.showEquipmentSection && !this.showEmployeeSection) {
                    timesheet.WorkTime = 0;
                } else {
                    timesheet.WorkTime = workTime > 0 ? workTime / 60 : this.bonusQty! * 1;
                }

                timesheet.Comment = this.comment;
                timesheet.Shift = this.shift;
                timesheet.Bonus = currentBonus;
                timesheet.Group = this.group ? this.group.id : "";
            }

            if (this.showEquipmentSection) {
                timesheet.EquipmentCode = this.equipmentCode ? this.equipmentCode.id : "";
                timesheet.EquipmentActivityCode = this.equipmentActivityCode ? this.equipmentActivityCode().id : "";
                timesheet.EquipmentGroupCode = this.equipmentGroupCode ? this.equipmentGroupCode.id : "";

                const equipmentWorkTime = moment(this.equipmentWorkTime).diff(moment(dateHelper.getDate()), "minutes");
                timesheet.EquipmentWorkTime = equipmentWorkTime > 0 ? equipmentWorkTime / 60 : 0;
            }

            if (this.isLaborType && this.showEmployeeSection) {
                timesheet.Employee = { Id: this.employee.id };
            }

            const data = {
                Timesheets: [timesheet]
            };

            //set lastshifused
            await serviceService.setLabor(this.serviceType, this.dispatchId, data).then(() => {
                if (this.shiftList.length > 0) {
                    defaultService.setServiceLastShiftUsed(this.shift);
                }
                routerHelper.hideLoading();
                routerHelper.navigateBack();
            });

            await this.loadLabor();
        }
    }

    public async validate(): Promise<boolean> {
        this.validationController.addObject(this);
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
        this.validationController.removeObject(this);
        return isValid;
    }

    public toggleBonusConfigCheckbox(config: any): void {
        config.value = !config.value;
    }
}
